import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Network, ModalContext, DNDContext } from '@mediavine/ui'
import Routes, { PublicRoutes } from './Routes'
import { useAuthContext, useTracking } from 'helpers/hooks'
import cache from 'helpers/cache'
import { UserData, withGravatar, getUserData } from 'helpers/context'
import ErrorBoundary from 'components/ErrorBoundary'
import Intercom from 'components/Intercom'
import { FeatureFlagProvider } from 'components/FeatureFlag'
import TwoFactorForm from 'views/Authorization/Verify2FA'
import { ILoginData } from 'views/Authorization/Login'
import { useSendTrackingEvent } from 'helpers/trackingEvents'
import { SESSION_STORAGE_PRO_DISMISSED_IDS_VAR } from 'components/modals/ProModal'
import { SiteDataContext, SiteDataProvider } from 'state/context/site/siteCtx'
import { getJwt } from 'helpers/data'
import ErrorToast from 'components/errorToast'
import Hotjar from '@hotjar/browser'
import LogRocket from 'logrocket'

interface AppProps {
  token: string
  userData: UserData
}

function App({ token, userData }: AppProps) {
  const { id } = React.useContext(SiteDataContext)
  // Admins don't have default sites
  const shouldShowAllSitesView =
    userData.roles.includes('admin') || userData.site_ids.length > 1
  const defaultSite = shouldShowAllSitesView ? undefined : userData.site_ids[0]
  const authContext = useAuthContext(token, userData)

  // Don't track user data until the site context has the actual site ID available
  if (process.env.REACT_APP_ENV === 'production' && id !== 0) {
    initTracking(userData, 'hotjar', id)
    initTracking(userData, 'logrocket', id)
  }

  return (
    <DNDContext>
      <ModalContext>
        <div id="invalid-token"></div>
        <Network.JWTProvider value={authContext}>
          <FeatureFlagProvider>
            <Helmet
              titleTemplate="%s | Mediavine Dashboard"
              defaultTitle="Mediavine Dashboard"
            />
            <Routes defaultSite={defaultSite} />
            <ErrorToast />
          </FeatureFlagProvider>
        </Network.JWTProvider>
      </ModalContext>
    </DNDContext>
  )
}

function initTracking(
  userData: UserData,
  trackingType: string,
  siteId: number
) {
  const userId = userData.id
  const userRoles: string[] = []

  userRolesLoop(userData.roles, userRoles)
  userData.site_users.forEach((site) => {
    if (site.site_id === siteId) {
      userRolesLoop(site.roles, userRoles)
    }
  })

  if (trackingType === 'hotjar') {
    const hotjarSiteId = 3743705
    const hotjarVersion = 6
    const formattedRoles = userRoles
      .map((role) => {
        const roleTitle = role.charAt(0).toUpperCase() + role.slice(1)
        return `User Role: ${roleTitle}`
      })
      .join(',')

    Hotjar.init(hotjarSiteId, hotjarVersion)

    Hotjar.identify(`${userId}`, {
      formattedRoles
    })
  } else if (trackingType === 'logrocket') {
    const logRocketAppId = 'b4rkgp/mv-reporting-dashboard'

    LogRocket.init(logRocketAppId)

    const lrRoles: { [key: string]: boolean } = {
      admin: false,
      owner: false,
      ad_settings: false,
      video: false,
      reporting: false,
      payment: false
    }

    userRoles.forEach((role) => {
      if (role in lrRoles) {
        lrRoles[role] = true
      }
    })

    LogRocket.identify(`${userId}`, lrRoles)
  }
}

function userRolesLoop(roles: string[], userRoles: string[]) {
  roles.forEach((role) => {
    if (!userRoles.includes(role)) {
      userRoles.push(role)
    }
  })

  return userRoles
}

export default function AuthedApp() {
  const { id: siteId } = React.useContext(SiteDataContext)
  const [userData, setUserData] = React.useState<UserData | null>(
    cache.get('userData')
  )
  const [jwt, setJwt] = React.useState<string | null>(getJwt())
  const [twoFactorRequired, setTwoFactorRequired] = React.useState<boolean>(
    cache.get('twoFactorRequired')
  )
  const [twoFactorOk, setTwoFactorOk] = React.useState<boolean>(
    cache.get('twoFactorOk')
  )

  const sendTrackingEvent = useSendTrackingEvent()
  useTracking(process.env.REACT_APP_GA_MEASUREMENT_ID, userData)

  // Refetch the user data once per sessions
  useEffect(() => {
    if (userData?.id && jwt) {
      getUserData(userData.id, jwt).then((data) => {
        setUserData(data)

        // if 2FA was reset in the admin dashboard and it differs from the saved localStorage flag
        // we want to signout the user and force a user data refetch which normally happens once per session
        if (
          data?.twilio_verify_enabled !== cache.get('twoFactorRequired') &&
          cache.get('twoFactorOk') === true
        ) {
          // logout
          localStorage.clear()
          sessionStorage.clear()
          if (caches) {
            caches.keys().then((cacheNames) => {
              cacheNames.forEach((cacheName) => {
                caches.delete(cacheName)
              })
            })
          }
          window.location.href = '/'
        }
      })
    }
  }, [userData?.id, jwt, setUserData])

  // When user data becomes available, update the cache
  useEffect(() => {
    cache.set({
      jwt,
      userData,
      twoFactorRequired,
      twoFactorOk
    })
  }, [jwt, userData, twoFactorRequired, twoFactorOk])

  // clear session storage on refresh for pro modal declined/dismiss behavior
  useEffect(() => {
    window.onunload = function () {
      sessionStorage.removeItem(SESSION_STORAGE_PRO_DISMISSED_IDS_VAR)
    }

    // identify user in hubspot
    const _hsq = (window as any)._hsq || []
    _hsq.push([
      'identify',
      { dashboard_site_id: siteId, email: userData?.email }
    ])
    // eslint-disable-next-line
  }, [])

  const handleLogin = useCallback(
    (loginData: ILoginData) => {
      setJwt(loginData.data.token)
      setUserData(withGravatar(loginData.data.user))
      setTwoFactorRequired(loginData.data.twoFactorRequired)
      sendTrackingEvent({ name: 'login' })
    },
    [sendTrackingEvent]
  )

  if (!jwt || !userData?.id) {
    return <PublicRoutes onLogin={handleLogin} />
  }

  if (twoFactorRequired && !twoFactorOk) {
    return (
      <TwoFactorForm
        userId={userData.id}
        onVerified={() => setTwoFactorOk(true)}
      />
    )
  }

  return (
    <ErrorBoundary>
      <SiteDataProvider>
        <App token={jwt} userData={userData} />
        {!userData.roles.includes('admin') && (
          <Intercom email={userData.email} user_hash={userData.user_hash} />
        )}
      </SiteDataProvider>
    </ErrorBoundary>
  )
}
