import { ThemeOptions } from 'helpers/theme'
import { darken } from 'polished'

const palette = (theme: ThemeOptions | undefined, isDarkMode: boolean) => {
  // The light and dark theme colors are set the same currently until we get styles for them.
  const getTheme = isDarkMode
    ? {
        default: {
          main: '#A3DBDF',
          mainAlt: '#A3DBDF',
          dark: '#78A1A1',
          light: '#B5E2E5',
          contrastText: '#000000DE'
        },
        pro: {
          main: '#3E74C1',
          mainAlt: '#3E74C1',
          dark: '#004990',
          light: '#F5F9FF'
        },
        premiere: {
          main: '#5A71AD',
          mainAlt: '#5A71AD',
          dark: darken(0.1, '#5A71AD'),
          light: '#EAEEF9'
        }
      }
    : {
        default: {
          main: '#2D7276',
          mainAlt: '#2D7276',
          dark: '#004044',
          light: '#A3DBDF'
        },
        pro: {
          main: '#3E74C1',
          mainAlt: '#3E74C1',
          dark: '#004990',
          light: '#F8FCFF'
        },
        premiere: {
          main: '#5A71AD',
          mainAlt: '#5A71AD',
          dark: darken(0.1, '#5A71AD'),
          light: '#FBFCFF'
        }
      }

  return {
    primary: theme ? { ...getTheme[theme] } : { ...getTheme.default },
    secondary: {
      main: '#E56338',
      dark: '#F50057'
    },
    text: {
      primary: isDarkMode ? '#FFF' : '#1E1E1EDE',
      secondary: isDarkMode ? '#FFF' : '#1E1E1E99'
    },
    success: {
      main: '#72BD7E',
      dark: '#549F60',
      light: '#72BD7E'
    },
    error: {
      main: '#DA7A7A',
      dark: '#621c16',
      light: '#FFFAFA'
    },
    info: {
      main: '#2196F3',
      dark: '#1976D2',
      light: '#E3F2FD'
    },
    warning: {
      main: '#E5BF64',
      dark: '#D1AB50',
      light: '#FFFDF7'
    },
    divider: isDarkMode ? 'rgb(97, 97, 97)' : 'rgba(0, 0, 0, 0.12)',
    stack: {
      main: isDarkMode ? '#2f2f2f' : '#ffffff'
    },
    action: {
      disabled: 'rgba(255, 255, 255, 0.3)',
      active: isDarkMode ? '#FFFFFF' : '#0000008A',
      hover: '#0000000A',
      focus: '#0000001F',
      disabledBackground: 'rgba(255, 255, 255, 0.3)'
    },
    background: {
      default: isDarkMode ? '#303030' : '#fafafa',
      paper: isDarkMode ? '#171717' : '#fafafa'
    },
    tealTipHeader: {
      main: isDarkMode ? '#1E1E1EDE' : '#FAFAFA'
    }
  }
}

export default palette
