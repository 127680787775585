import React from 'react'
import {
  Rule,
  Text,
  Button,
  Icon,
  ScreenReader,
  Grid,
  Flex,
  Network
} from '@mediavine/ui'
import { Link, useTheme } from '@mui/material'
import styled from 'styled-components'
import { GlobalThemeContext } from 'helpers/theme'
import MediavineLogo from './MediavineLogo'
import { useIsMobile } from 'helpers/hooks'
import { LargePremiereLogo } from './PremiereLogo'
import { HideableNavigation } from './NoNavigation'
import { openWidget } from './Intercom'
import { UserContextProps } from 'helpers/context'
import { ProFooterLogo } from './ProFooterLogo'
import { SiteDataContext } from 'state/context/site/siteCtx'
import { PACKAGE_JSON_VERSION } from 'helpers/constants'

const FooterNav = styled.ul`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding-inline-start: 0px;
  padding-bottom: 50px;
`

const FooterNavItem = styled.li`
  margin-right: 8px;
  font-weight: 400;
  font-size: 0.9rem;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  line-height: 1.6;

  &:last-child {
    margin-right: 0;
  }
`

const DarkModeButton = () => {
  const { toggleDarkMode } = React.useContext(GlobalThemeContext)
  return (
    <Button kind="icon" onClick={toggleDarkMode}>
      <Icon kind="glasses" size="lg" />
      <ScreenReader>Toggle Dark Mode</ScreenReader>
    </Button>
  )
}

const setLogo = (currentTheme: string) => {
  if (currentTheme === 'premiere') {
    return <LargePremiereLogo />
  } else if (currentTheme === 'pro') {
    return <ProFooterLogo />
  } else {
    return <MediavineLogo />
  }
}

interface FirstTimeOnboardingFooterWrapperProps {
  children: any
}

const FirstTimeOnboardingFooterWrapper = ({
  children
}: FirstTimeOnboardingFooterWrapperProps) => {
  const {
    userData: { site_ids }
    // @ts-ignore
  } = React.useContext<UserContextProps>(Network.JWTContext)

  const { disable_onboarding_wizard } = React.useContext(SiteDataContext)
  const newOnboardingEnabled = !disable_onboarding_wizard
  const userFirstTimeOnboarding = site_ids?.length === 1

  return userFirstTimeOnboarding && newOnboardingEnabled ? (
    <>{children}</>
  ) : (
    <HideableNavigation>{children}</HideableNavigation>
  )
}

export default function SiteFooter() {
  const isMobile = useIsMobile(BREAKPOINT)
  const { currentTheme } = React.useContext(GlobalThemeContext)
  const theme = useTheme()

  return (
    <FirstTimeOnboardingFooterWrapper>
      <Grid padding="lg 0" justify="center">
        {!isMobile && <Rule color="border" />}
        {setLogo(currentTheme as string)}
        {isMobile && (
          <Text size=".9rem" weight="400">
            &copy; {new Date().getFullYear()} Mediavine
          </Text>
        )}
        <FooterNav>
          <Grid gutter="lg">
            <Flex>
              <FooterNavItem>
                <Link
                  color="inherit"
                  underline="none"
                  target="_blank"
                  href="https://www.mediavine.com/terms-of-service/"
                >
                  Terms of Use |
                </Link>
              </FooterNavItem>
              <FooterNavItem>
                <Link
                  color="inherit"
                  underline="none"
                  target="_blank"
                  href="https://www.mediavine.com/privacy-policy/"
                >
                  Privacy Policy |
                </Link>
              </FooterNavItem>
              <FooterNavItem>
                <Link
                  color="inherit"
                  underline="none"
                  target="_blank"
                  href="https://www.mediavine.com/legal-and-privacy-center/"
                >
                  Mediavine Legal Center |
                </Link>
              </FooterNavItem>
              <FooterNavItem>
                <Link
                  color="inherit"
                  underline="none"
                  component="button"
                  sx={{
                    '&:hover': {
                      color: theme.palette.primary.main
                    }
                  }}
                  onClick={() => openWidget()}
                >
                  Contact Us
                </Link>
              </FooterNavItem>
              <FooterNavItem>
                <DarkModeButton />
              </FooterNavItem>
            </Flex>
            <Text size="sm" align="center">
              Mediavine Dashboard {PACKAGE_JSON_VERSION}
            </Text>
          </Grid>
        </FooterNav>
      </Grid>
    </FirstTimeOnboardingFooterWrapper>
  )
}

const BREAKPOINT = 899
